// /*!

//  =========================================================
//  * Paper Dashboard React - v1.1.0 based on Paper Dashboard 2 - v2.0.0
//  =========================================================

//  * Product Page: https://www.creative-tim.com/product/paper-dashboard-2
//  * Copyright 2019 Creative Tim (http://www.creative-tim.com)

//  * Designed by www.invisionapp.com Coded by www.creative-tim.com

//  =========================================================

//  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

//  */

@import "paper-dashboard/variables";
@import "paper-dashboard/mixins";

// // Plugins CSS
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";

// // Core CSS
@import "paper-dashboard/buttons";
// @import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/checkboxes-radio";

// // components
@import "paper-dashboard/navbar";
@import "paper-dashboard/page-header";
@import "paper-dashboard/dropdown";
@import "paper-dashboard/alerts";
@import "paper-dashboard/images";
// @import "paper-dashboard/nucleo-outline";
@import "paper-dashboard/tables";
@import "paper-dashboard/sidebar-and-main-panel";
@import "paper-dashboard/footers";
@import "paper-dashboard/fixed-plugin";

// // // cards
@import "paper-dashboard/cards";
@import "paper-dashboard/cards/card-plain";
@import "paper-dashboard/cards/card-chart";
@import "paper-dashboard/cards/card-user";
@import "paper-dashboard/cards/card-map";
@import "paper-dashboard/cards/card-stats";

@import "paper-dashboard/responsive";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.coupons-card-cover-more {
  width: 100%;
  height: 100px;

  position: absolute;
  bottom: 0;
  margin: 0px auto;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 60%
  );
}

.coupons-card-cover-less {
  width: 100%;
  height: initial;

  position: absolute;
  bottom: 0;
  margin: 0px auto;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 60%
  );
}

.extra-margin-bottom {
  margin-bottom: 30px;
}
